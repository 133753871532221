import { DISMISSED_BANNER_LOCAL_STORAGE_KEY } from 'constants/banners'

/**
 * Function to set the banner to show after a certain time after being dismissed
 * @param {Object} params
 * @param {String} params.id - banner id, used for local storage key
 * @param {Number} params.showAfter - value in seconds, -1 means indefinitely
 */
export const setBannerShowAfterTime = ({ id, showAfter }) => {
  const localStorageKey = `${DISMISSED_BANNER_LOCAL_STORAGE_KEY}-${id}`

  const showAfterTime =
    showAfter > 0 ? new Date().getTime() + showAfter * 1000 : -1

  localStorage.setItem(localStorageKey, showAfterTime)
}

export const shouldBannerBeHidden = ({ id }) => {
  const localStorageKey = `${DISMISSED_BANNER_LOCAL_STORAGE_KEY}-${id}`
  const localStorageValue = localStorage.getItem(localStorageKey)

  if (localStorageValue === undefined) {
    return false
  }

  if (localStorageValue === '-1') {
    return true
  }

  const showAfterTime = parseInt(localStorageValue, 10)
  const currentTime = new Date().getTime()

  return showAfterTime > currentTime
}
